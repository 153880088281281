import React from "react";
import { graphql } from "gatsby";
import { document } from "browser-monads";
import { useSize } from "ahooks";

// Components
import FurtherReadingCard from "../../components/furtherReadingLinks/furtherReading";
import Hero from "../../components/hero";
import HeroRedesigned from "../../components/heroRedesigned";
import Layout from "../../components/layout";
import Pagination from "../../components/pagination";
import SideMenu from "../../components/sideMenu";
import ShareComponent from "../../components/shareComponent";
import Seo from "../../components/seo/seo";

// Constants
import API from "../../constants/api";
import BREAKPOINTS from "../../constants/breakpoints";

// Helpers
import generateRandomId from "../../helpers/generateRandomId";
import sortByIsPinned from "../../helpers/sortByIsPinnedField";

// Hooks
import useBreadcrumbs from "../../hooks/useBreadcrumbs";
import useCategoriesList from "../../hooks/useCategoriesMenu";

// Styles
import * as styles from "../blogsStyles.module.scss";

const EvCategory = ({ data, pageContext }) => {
  const size = useSize(document.querySelector("body"));

  const {
    allSanityEv,
    allSanityEvCategory,
    sanityEvCategory,
    sanityEvListing,
  } = data;
  const { currentPage, totalPages } = pageContext;
  const heroContent = sanityEvCategory?._rawHero;
  const slug = sanityEvCategory?._rawSlug.current;
  const seo = sanityEvCategory?.seo;
  const heroTitle = heroContent.heroText.titleText;
  const mainCategoryName = sanityEvListing?.tabs.content.catCustomName;
  const HeroComponent = heroContent?.isRedesigned ? HeroRedesigned : Hero;

  const categoriesList = useCategoriesList(
    API.events,
    allSanityEvCategory?.nodes
  );

  const breadcrumbsList = [
    {
      _key: generateRandomId(),
      urlLabel: mainCategoryName ?? "All Categories",
      url: API.events.slice(1),
    },
    ...categoriesList.slice(1),
  ];

  const breadcrumbs = useBreadcrumbs(breadcrumbsList, false);

  return (
    <Layout stickyHeader isPage>
      <Seo
        title={heroTitle}
        image={heroContent?.bgImage}
        breadcrumbs={breadcrumbs}
        path={pageContext?.pagePath}
        {...seo}
      />
      <HeroComponent
        breadcrumbs={breadcrumbs}
        image={heroContent?.bgImage}
        imageClassName={styles.heroImg}
        subTitle={heroContent?.heroText.subtitleText}
        title={heroContent?.heroText.titleText}
      />
      <div className={styles.mainContentWrapper}>
        <div className={styles.sideMenuWrapper}>
          <SideMenu
            getPathFunc={API.evCategory}
            hasMarks
            hasSharing
            list={categoriesList}
          />
        </div>
        <div className={styles.cardsWrapper}>
          <div className={styles.cards}>
            {(allSanityEv?.nodes?.sort(sortByIsPinned) ?? []).map((item) => (
              <FurtherReadingCard
                key={generateRandomId()}
                cardInfo={{ ...item, _type: item.tabs.evCard._type }}
              />
            ))}
          </div>
          {totalPages > 1 && (
            <div className={styles.bottomContentWrapper}>
              <Pagination
                base={`events/${slug}`}
                currentPage={currentPage}
                totalPages={totalPages}
              />
            </div>
          )}
        </div>
        {size?.width < BREAKPOINTS.lg && <ShareComponent />}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query ($slug: String!, $skip: Int!, $limit: Int!) {
    sanityEvCategory(slug: { current: { eq: $slug } }) {
      title
      _rawHero(resolveReferences: { maxDepth: 10 })
      _rawSlug
      seo {
        canonical
        description
        metaTitle
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
        removeSitemap
      }
    }
    allSanityEv(
      sort: { fields: tabs___content___eventDate, order: DESC }
      filter: {
        tabs: { content: { category: { slug: { current: { eq: $slug } } } } }
      }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      nodes {
        tabs {
          content {
            title
            isPinned
            isLanding
            category {
              title
              slug {
                current
              }
              hero {
                bgImage {
                  asset {
                    _id
                  }
                }
              }
            }
            slug {
              current
            }
          }
          evCard {
            excerpt
            image {
              asset {
                _id
                assetId
              }
            }
            _type
          }
        }
      }
    }
    allSanityEvCategory {
      nodes {
        id
        _rawSlug
        catColor
        title
      }
    }
    sanityEvListing {
      tabs {
        content {
          catCustomName
        }
      }
    }
  }
`;

export default EvCategory;
