const sortByIsPinned = (a, b) => {
  if (a.tabs.content.isPinned === true) {
    return -1;
  } else if (b.tabs.content.isPinned === true) {
    return 1;
  } else {
    return 0;
  }
};

export default sortByIsPinned;
